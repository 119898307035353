<template>
  <div>
    <div class="top_banner x_between_y_center">
        <div class="y_center" style="cursor: pointer;">
          <div class="y_center" @click="showSideMenu">
            <div class="menu_text" 
                 :style="{color:theme === 'white' ? '#fff' : '#000'}">MENU
            </div>
            <img :src="theme === 'white' ? 
                 require('@/assets/image/home/twoPage/menu_icon.svg') : 
                 require('@/assets/image/home/threePage/menu_icon.svg')" 
                 class="menu_icon">
          </div>
          <a :href="$store.state.hash" @click="backHome">
            <img :src="theme === 'white' ? 
                 require('@/assets/image/home/twoPage/home_icon.svg') : 
                 require('@/assets/image/home/threePage/home_icon.svg')"
                 class="home_icon">
          </a>
        </div>
        <img  src="@/assets/image/home/onePage/company_logo.png" class="company_logo" @click="backHome1">
    </div>
    <side-menu @closeSideMenu="closeSideMenu" :openSideMenu="openSideMenu"></side-menu>
  </div>
    
</template>

<script>
import sideMenu from "@/components/sideMenu.vue"
export default {
    name: 'twoPage',
    props:{
      theme:{
        type:String,
        default:"white"
      }
    },
    data(){
        return {
          openSideMenu:0,
        }
    },
    methods:{
      backHome(){
        this.$emit("backHome")
      },
      backHome1(){
        this.$store.commit("changeHash","#slide1")
      },
      showSideMenu(){
        if (this.openSideMenu === 1) {
          this.openSideMenu = 0
          this.$store.commit("showSideMenu",false)
        }else{
          this.openSideMenu = 1
          this.$store.commit("showSideMenu",true)
        }
      },
      closeSideMenu(){
        this.openSideMenu = 0
      },
    },
    components:{
      sideMenu
    }
}
</script>

<style lang="scss" scoped>
.top_banner{
  .menu_text{
    font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  .menu_icon{
    width:20px;
    margin:0 20px 0 16px
  }
  .home_icon{
      width:20px;
  }
  .company_logo{
    width: 40px;
  }
}
</style>