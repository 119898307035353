import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},

	{
		path: '/productDynamicDetail',
		name: 'productDynamicDetail',
		component: ()=>import("../views/productDynamicDetail.vue"),
	},
	{
		path: '/customDevelopment',
		name: 'customDev',
		component: ()=>import("../views/customDevelopment.vue"),
	},
	{
		path: '/enterpriseDeployment',
		name: 'enterpriseDeployment',
		component: ()=>import("../views/enterpriseDeployment.vue"),
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: ()=>import("../views/feedback.vue"),
	},
	{
		path: '/productInfomation',
		name: 'productInfomation',
		component: ()=>import("../views/productInfomation.vue"),
	},
	{
		path: '/understand',
		name: 'understand',
		component: ()=>import("../components/understand.vue"),
	},
	{
		path: '/regist',
		name: 'regist',
		component: ()=>import("../views/regist.vue"),
	},
	{
		path: '/forgetPwd',
		name: 'forgetPwd',
		component: ()=>import("../views/forgetPwd.vue"),
	},
	{
		path: '/productPrice',
		name: 'productPrice',
		component: ()=>import("../views/productPrice.vue"),
	},
	{
		path: '/userAgreement',
		name: 'userAgreement',
		component: ()=>import("../views/userAgreement.vue"),
	},
	{
		path: '/privacyAgreement',
		name: 'privacyAgreement',
		component: ()=>import("../views/privacyAgreement.vue"),
	},
	{
		path: '/share',
		name: 'share',
		component: ()=>import("../views/share.vue"),
	},
	{
		path: '/FAQ',
		name: 'FAQ',
		component: ()=>import("../views/FAQ.vue"),
	},
	{
		path: '/payPage',
		name: 'PayPage',
		component: ()=>import("../views/payPage.vue"),
	},
	// {
	// 	path: '*',
	// 	component:()=>import("../views/error404.vue"),// (resolve) => require(['../views/error404.vue'], resolve)
	// }
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	base: '/BIMOMweb/',
	routes
})

export default router
