<template>
  <div>
    <div class="popup animate__animated"
         :style="{left:startAnimate ? '-1%' : '-50%'}"
         :class="[startAnimate ? (openSideMenu === 1 ? 'animate__slideInLeft' : 'animate__slideOutLeft') : '']">
      <!-- 关闭按钮 -->
      <div class="y_center" style="cursor:pointer" @click="closePopup">
        <img src="@/assets/image/popup/popup_close.svg" class="popup_close">
        <div class="close_text">close</div>
      </div>
      <!-- 一级菜单 -->
      <div class="column">
        <div v-for="(item,index) in menuList" :key="index" 
             class="menu_item"
             @click="selMenu(index)">
          <div class="menu_name_box">
            <div class="y_center">
              <div class="zh" v-text="item.zh"></div>
              <div class="en" v-text="item.en"></div>
            </div>
            <!-- 二级菜单 -->
            <div class="select_box animate__animated" 
                 :class="[current_open_second_menu === index ? 'animate__fadeInLeft' : 'animate__fadeOutLeft']">
              <div class="line"></div>
              <div v-if="index === 0 || index === 2">
                <a v-for="(row,n) in item.selectList" :key="n" 
                  v-text="row.name" 
                  class="select_row"
                  :href="hash"
                  @click="routerJump(index,n)">
                </a>
              </div>
              <div v-else>
                <div v-for="(it,i) in item.selectList" :key="i" 
                  v-text="it.name" 
                  class="select_row"
                  @click="routerJump(index,i)">
                </div>
              </div>
            </div>
          </div>
          <div class="main_icon">
            <img :src="item.icon" style="width:100%">
          </div>
          
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
export default {
    name: 'twoPage',
    props:{
        openSideMenu:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
          hash:"",
          current_open_second_menu:"",
          startAnimate:false,
          menuList:[
            {
              zh:"产品",
              en:"PRODUCT",
              spread:false,
              icon:require("../assets/image/popup/menu/icon0.svg"),
              icon_active:require("../assets/image/popup/menu/icon0_sel.svg"),
              selectList:[
                {id:0,name:"BIMOM-构件库"},
                {id:1,name:"BIMOM-BCF云"},
                {id:2,name:"BIMOM-建筑包"},
                {id:3,name:"BIMOM-铝模"},
                {id:4,name:"AcurRebar钢筋"},
              ]
            },
            {
              zh:"技术",
              en:"TECHNOLOGY",
              spread:false,
              icon:require("../assets/image/popup/menu/icon3.svg"),
              icon_active:require("../assets/image/popup/menu/icon3_sel.svg"),
              selectList:[
                {id:0,name:"定制开发"},
                {id:1,name:"企业部署"},
                {id:2,name:"问题反馈"},
              ]
            },
            {
              zh:"企业",
              en:"ABOUT US",
              spread:false,
              icon:require("../assets/image/popup/menu/icon0.svg"),
              icon_active:require("../assets/image/popup/menu/icon0_sel.svg"),
              selectList:[
                {id:0,name:"产品动态"},
                {id:1,name:"关于我们"},
              ]
            },
            {
              zh:"资料",
              en:"OTHER",
              spread:false,
              icon:require("../assets/image/popup/menu/icon3.svg"),
              icon_active:require("../assets/image/popup/menu/icon3_sel.svg"),
              selectList:[
                {id:0,name:"产品下载"},
                {id:1,name:"帮助文档"},
                {id:2,name:"注册账号"},
              ]
            },
          ]
        }
    },
    watch:{
        openSideMenu:function(val){
          if (val === 1) {
            this.startAnimate = true
          }
        }
    },
    methods:{
        closePopup(){
            this.$emit("closeSideMenu")
            this.$store.commit("showSideMenu",false)
        },
        selMenu(index){
            this.current_open_second_menu = index
        },
        routerJump(index,i){
            switch (index) {
                case 0:
                switch (i) {
                  case 0:
                    this.hash = "#slide2"
                    break;
                  case 1:
                    this.hash = "#slide3"
                    break;
                  case 2:
                    this.hash = "#slide4"
                    break;
                  case 3:
                    this.hash = "#slide5"
                    break;
                  case 4:
                    this.hash = "#slide6"
                    break;
                }
                break;
                case 1:
                this.hash = null
                switch (i) {
                  case 0:
                    this.$router.push("/customDevelopment")
                    break;
                  case 1:
                    this.$router.push("/enterpriseDeployment")
                    break;
                  case 2:
                    this.$router.push("/feedback")
                    break;
                }
                break;
                case 2:
                switch (i) {
                  case 0:
                    this.hash = "http://localhost:8080/#slide6"
                    break;
                  case 1:
                    this.hash = "http://localhost:8080/#slide7"
                    break;
                }
                break;
                case 3:
                this.hash = null
                switch (i) {
                  case 0:
                    this.$router.push("/productInfomation")
                    break;
                  case 1:
                    this.$router.push("/productInfomation")
                    break;
                  case 2:
                    this.$router.push("/regist")
                    break;
                }
                break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.popup{
  position: absolute;
  bottom:  0;
  width: 450px;
  height: 90vh;
  border: 6px solid rgb(116, 93, 93);
  background: #000;
  color: #797979;
  border-radius: 20px;
  padding: 40px 90px;
  z-index: 999;
  .close_text{
    font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 30px;
    margin-left: 10px;
  }
  .popup_close{
      width:20px
  }
}
// 一级菜单
.menu_item{
  margin-top: 30px;
  cursor: pointer;
  .menu_name_box{
    position: relative;
    .zh{
      font-family: '幼圆', sans-serif;
      writing-mode:vertical-lr;
      letter-spacing: 4px;
      font-size: 20px;
    }
    .en{
      font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
      font-size: 35px;
      margin-left: 20px;
    }
  }
  .main_icon{
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-left: 60px;
  }
}
// 二级菜单
.select_box{
  position: absolute;
  left: 65%;
  top: 20px;
  color: #797979;
  .line{
    width: 350%;
    position: absolute;
    right: 0%;
    height: 3px;
    background: linear-gradient(to right, rgb(255, 203, 154) 20%, rgb(248, 129, 17) 100%);
    border-radius: 20px;
  }
  .select_row{
    font-size: 16px;
    cursor: pointer;
    text-align: right;
    padding-top: 20px;
    padding-bottom: 12px;
    width: 150px;
    display: block;
    color: #797979;
    &:hover{
      color: #fff;
      border-bottom: 1px solid #797979;
    }
  }
}
</style>