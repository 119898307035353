import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import anime from "animejs"
import moment from "moment"
import 'moment/locale/zh-cn'

import understandCom from "../src/assets/utils/registerComponent/understand.js"
import tool from "../src/assets/utils/tool.js"

import "amfe-flexible"

import "./assets/css/public.css"
import "./assets/css/reset.css"
import "./assets/css/flex.css"
import "./assets/font/font.css"
import "./assets/utils/swiper/swiper-bundle.min.css"

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css'

import axios from "axios";
import Video from 'video.js'
import 'video.js/dist/video-js.css'


Vue.prototype.$video = Video
Vue.prototype.axios=axios;

Vue.use(ElementUI)
Vue.use(understandCom)

Vue.prototype.$anime = anime
Vue.prototype.$moment = moment
Vue.prototype.$tool = tool

Vue.config.productionTip = false

Vue.prototype.VUE_APP_BASE_API='http://www.wkhzz.com:8085/BcfMod/';
// Vue.prototype.VUE_APP_BASE_API='http://vanxis.tpddns.cn:8085/';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
