<template>
<div class="bgc" :style="{background:show_mouse_icon ? '#000' : '#191919'}">
  <div class="over_lay">
    <img src="@/assets/image/home/onePage/building.png" 
       class="building_shadow trans_x animate__animated animate__fadeIn animate__slow"
       :class="['animate__delay-' + base_animate_delay + 's']">
  </div>
  <img src="@/assets/image/home/onePage/building.png" 
       class="building animate__animated animate__fadeInUp animate__slow"
       :class="['animate__delay-' + base_animate_delay + 's']">
  <!-- 顶部栏 -->
  <div class="x_between_y_center animate__animated animate__fadeIn animate__slow"
       style="position:relative;z-index:999"
       :class="['animate__delay-' + base_animate_delay + 's']">
      <img src="@/assets/image/home/onePage/company_logo.png" class="company_logo">
      <div class="topNav_box x_between_y_center">
      <div v-for="(item,index) in topNavList" :key="index" @mouseleave="mouseLeave(index)">
          <div class="nav_item xy_center" 
               :style="{color:item.spread ? '#fff' : '#797979'}"
               @mouseenter="mouseEnter(index)">
            <a v-text="item.name" class="name" :href="hash" @click="routerJump(index)"></a>
            <img v-if="item.icon" :src="item.spread ? item.icon_active : item.icon" class="icon">
          </div>
          <!-- 下拉框 -->
          <div class="select_box hide">
            <div v-if="index !== 3 || index !== 5">
              <a v-for="(row,n) in item.selectList" :key="n" 
                v-text="row.name" 
                class="select_row"
                :href="hash"
                @click="routerJump(index,n)">
              </a>
            </div>
            <div v-else>
              <div v-for="(it,i) in item.selectList" :key="i" 
                v-text="it.name" 
                class="select_row"
                @click="routerJump(index,i)">
              </div>
            </div>
          </div>
      </div>
      </div>
  </div>
  <div style="bottom:40%" 
       id="fade_out"
       class="company_info animate__animated animate__fadeOutUp animate__slow"
       :class="['animate__delay-' + base_animate_delay + 's']">
      <div class="company">
        <span class="name">悟空＆展昭</span>
        <span class="name_ext">科技</span>
      </div>
      <div class="company_sentense">满足您对参数化模型应用的所有想象</div>
  </div>
  <div style="bottom:54%" 
       class="company_info animate__animated animate__fadeIn" 
       :class="['animate__delay-' + base_animate_delay + 's']">
      <div class="company">
        <span class="name">悟空＆展昭</span>
        <span class="name_ext">科技</span>
      </div>
      <div class="company_sentense">满足您对参数化模型应用的所有想象</div>
  </div>

  <div class="en_left animate__animated animate__fadeInLeft animate__slow"
       :class="['animate__delay-' + (base_animate_delay + 1) + 's']">PARAMETRIC
  </div>
  <div class="en_center animate__animated animate__fadeInUp animate__slower"
       :class="['animate__delay-' + (base_animate_delay + 1) + 's']">MODEL
  </div>
  <div class="en_right animate__animated animate__fadeInRight animate__slow"
       :class="['animate__delay-' + (base_animate_delay + 1) + 's']">CUSTOMIZATION
  </div>
  
  <div class="en_subtitle animate__animated animate__fadeInUp animate__slow"
       :class="['animate__delay-' + (base_animate_delay + 2) + 's']">
    Meet all your imagination about the application of parametric models
  </div>

  <!-- 鼠标按钮 -->
  <img v-if="show_mouse_icon" 
       src="@/assets/image/home/onePage/mouse_icon.svg" 
       class="trans_x mouse_icon animate__animated animate__fadeOut" 
       :class="['animate__delay-' + base_animate_delay + 's']"
       @click="startAnimate">

  <!-- 底部第三方链接按钮组 -->
  <link-group></link-group>
</div>
  
</template>

<script>
import linkGroup from "@/components/linkGroup.vue"
export default {
    name: 'onePage',
    data(){
        return {
            topNavList:[
                {id:0,name:"HOME",selectList:[],spread:false},
                {id:1,name:"BIMOM",selectList:[],spread:false},
                {
                  id:2,
                  name:"产品列表",
                  selectList:[
                      {id:0,name:"BIMOM-构件库"},
                      {id:1,name:"BIMOM-BCF云"},
                      {id:2,name:"BIMOM-建筑包"},
                      {id:3,name:"BIMOM-铝模"},
                      {id:4,name:"AcurRebar钢筋"},
                  ],
                  spread:false
                },
                {
                  id:3,
                  name:"技术服务",
                  selectList:[
                      {id:0,name:"定制开发"},
                      {id:1,name:"企业部署"},
                      {id:2,name:"问题反馈"},
                      {id:3,name:"常见问题"},
                  ],
                  spread:false
                },
                {
                  id:4,
                  name:"关于我们",
                  selectList:[
                      {id:0,name:"产品动态"},
                      {id:1,name:"关于我们"},
                  ],
                  spread:false
                },
                {
                  id:5,
                  name:"MENU",
                  selectList:[
                      {id:0,name:"产品价格"},
                      {id:1,name:"产品下载"},
                      {id:2,name:"帮助文档"},
                      {id:3,name:"注册账号"},
                      {id:4,name:"构件共享"},
                      {id:5,name:"进入BCF云"},
                      {id:6,name:"Archiad下载"},
                  ],
                  spread:false,
                  icon:require("../../assets/image/home/onePage/menu.svg"),
                  icon_active:require("../../assets/image/home/onePage/menu_active.svg")
                },
            ],
            hash:"",
            show_mouse_icon:true,
            base_animate_delay:1
        }
    },
    mounted(){
      setTimeout(() => {
        this.show_mouse_icon = false
      },2000)
      
    },
    methods:{
        startAnimate(){
          this.base_animate_delay = 1
          this.show_mouse_icon = false
        },
        mouseEnter(index){
          this.topNavList[index].spread = true
          var select_box = document.getElementsByClassName("select_box")[index]
          select_box.className = "select_box show"
          
        },
        mouseLeave(index){
          this.topNavList[index].spread = false
          var select_box = document.getElementsByClassName("select_box")[index]
          select_box.className = "select_box hide"
        },
        routerJump(index,i){
          console.log(index,i)
          switch (index) {
            case 1:
              this.hash = "#slide2"
              break;
            case 2:
              switch (i) {
                  case 0:
                      this.hash = "#slide2"
                      break;
                  case 1:
                      this.hash = "#slide3"
                      break;
                  case 2:
                      this.hash = "#slide4"
                      break;
                  case 3:
                      this.hash = "#slide5"
                      break;
                  case 4:
                      this.hash = "#slide6"
                      break;
              }
              break;
            case 3:
              this.hash = null
              switch (i) {
                  case 0:
                      this.$router.push("/customDevelopment")
                      break;
                  case 1:
                      this.$router.push("/enterpriseDeployment")
                      break;
                  case 2:
                      this.$router.push("/feedback")
                      break;
                  case 3:
                      this.$router.push("/FAQ")
                      break;
              }
              break;
            case 4:
              switch (i) {
                case 0:
                  this.hash = "#slide7"
                  break;
                case 1: 
                  this.hash = "#slide8"
                  break;
              }
              break;
            case 5:
              this.hash = null
              switch (i) {
                  case 0:
                      this.$router.push("/productPrice")
                      // alert("即将公布！敬请期待！");
                      break;
                  case 1:
                      this.$router.push("/productInfomation")
                      break;

                  case 2:
                      this.$router.push("/productInfomation")
                      break;
                  case 3:
                      // alert("即将开放，请先在Archicad端注册！");
                      this.$router.push("/regist")
                      break;
                  case 4:
                      this.$router.push("/share")
                      break;
                  case 5:
                      alert("即将上线！敬请期待！");
                      //this.$router.push("/payPage")
                      break;
                  case 6:
                      window.open("http://www.graphisoft.cn/c/download.html",'_blank')
                      break;
              }
              break;
          }
        }
    },
    components:{
      linkGroup
    }
}
</script>

<style scoped>
.animate__fadeOutUp{
  animation-name:fadeOutUp
}
@keyframes fadeOutUp{
  0% {
        opacity:1
      }
  to {
        opacity:0;
        transform:translate3d(0,-100px,0)
      }
}
</style>

<style lang="scss" scoped>
.bgc{
  width: 100%;
  height: 100vh;
  padding: 50px 70px !important;
  &::after{
    content: "";
    width: 100%;
    height: 160px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: #000;
    box-shadow: 0 0 100px 100px;
  }
}
.mouse_icon{
  cursor: pointer;
  bottom: 10%;
  width: 40px;
  z-index: 99;
}
.over_lay{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #000;
    opacity: .18;
}
.building_shadow{
  width: 100%;
  height: 95%;
  top: 20%;
}
.building{
  position: absolute;
  left: 29%;
  width: 40%;
  top: 42%;
}
// 动画的元素--start
.en_center{
  font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
  font-weight:bold;
  color: #fff;
  font-size: 150px;
  position: absolute;
  bottom: 30%;
  width: 92%;
  text-align: center;

}
.en_left{
  font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
  font-weight:bold;
  color: #02A7F0;
  font-size: 110px;
  position: absolute;
  top: 54%;
  left: 12%;
}
.en_right{
  font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
  font-weight:bold;
  color: #02A7F0;
  font-size: 110px;
  position: absolute;
  top: 54%;
  right: 7%;
}
// 动画的元素--end
.select_box{
    /*position:relative;*/
    padding-bottom:10px;
    z-index:99999;
    width: 120px;
  position: absolute;
  padding-top: 10px;
  color: #797979;
  .select_row{
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    display: block;
    color: #797979;
    &:hover{
      color: #fff;
      border-bottom: 1px solid #797979;
    }
  }
}
.show{
    z-index:999;
    /*position: relative;*/
  display: block;
  opacity: 0;
  animation-name:showSelect;
  animation-duration:.7s;
  animation-fill-mode : forwards
}
.hide{
  display: none;
  opacity: 1;
  animation-name:hideSelect;
  animation-duration:.5s;
  animation-fill-mode : forwards
}
@keyframes showSelect {
    100%{
        opacity: 1;
        display: block;
    }
}
@keyframes hideSelect {
    100%{
        opacity: 0;
        display: none;
    }
}
.topNav_box{
  width: 1060px;
}
.nav_item{
  font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
  color: #797979;
  font-weight: bold;
  cursor: pointer;
  height: 60px;
  width: 100px;
  position: relative;
  &:hover{
    border-bottom: 1px solid #797979;
  }
  .name{
    font-size: 18px;
    display: block;
    color: #797979;
  }
  .icon{
    width: 18px;
    margin-left: 10px;
  }
}
.company_logo{
  width: 40px;
}
.company_info{
  position: absolute;
  width: 91%;
  text-align: center;
  .company{
    margin-bottom: 10px;
    .name{
      font-weight:bold;
      letter-spacing: 10px;
      color: #02A7F0;
      font-size: 70px;
      font-family:'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
    }
    .name_ext{
      letter-spacing: 10px;
      color: #fff;
      font-size: 70px;
      font-family:'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
      }
  }
  .company_sentense{
    color: #fff;
    font-size: 29px;
    margin-top: 26px;
    font-family: '幼圆', sans-serif;
  }
}
.en_subtitle{
  font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
  text-align: center;
  color: #797979;
  font-size: 26px;
  bottom: 10%;
  position: absolute;
  width: 92%;
  z-index: 99;
}
</style>