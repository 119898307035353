<template>
    <div class="top_title_box">
        <span class="en_title" :class="mapFontSizeEn()" v-text="enTitle"></span>
        <span class="zh_title" :class="mspFontSizeZh()" v-text="zhTitle"></span>
        <span class="more_title" v-text="extra"></span>
    </div>
</template>

<script>
export default {
    props:{
        enTitle:{
            type:String,
            default:""
        },
        zhTitle:{
            type:String,
            default:""
        },
        extra:{
            type:String,
            default:""
        },
        fontSizeEn:{
            type:String,
            default:"font_normal_en"
        },
        fontSizeZh:{
            type:String,
            default:"font_normal_zh"
        },
    },
    data(){
        return {
            
        }
    },
    methods:{
        mapFontSizeEn(){
            console.log(this.fontSizeEn)
            if (this.fontSizeEn === 'small') {
                return "font_small_en"
            }else if(this.fontSizeEn === 'normal'){
                return "font_normal_en"
            }
        },
        mspFontSizeZh(){
            if (this.fontSizeZh === 'small') {
                return "font_small_zh"
            }else if(this.fontSizeZh === 'normal'){
                return "font_normal_zh"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.top_title_box{
    margin-top: 20px;
    .en_title{
        font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
        font-weight: 700;
        color: #FFFFFF;
        margin-right: 10px;
    }
    .font_small_en{
        font-size: 60px;
    }
    .font_normal_en{
        font-size: 72px;
    }
    .font_small_zh{
        font-size: 26px;
    }
    .font_normal_zh{
        font-size: 30px;
    }
    .zh_title{
        font-family: '幼圆', sans-serif;
        color: #02A7F0;
    }
    .more_title{
        font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
        color: #02A7F0;
        font-size: 16px;
        margin-left: 10px;
    }
}
</style>