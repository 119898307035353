<template>
<div class="bgm">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>

        <!-- 标题 -->
        <title-enzh enTitle="PRODUC NEWS" zhTitle="产品动态" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
        <!-- 列表 -->
        <div>
            <div v-for="(item,index) in list" :key="index" class="row" @click="dynamicDetail(item.title)">
                <div style="flex:2">
                    <img :src="image+item.title+'.png'" class="poster">
                </div>
                
                <div style="flex:7">
                    <div class="title ellipsis_one" v-text="item.title"></div>
                    <div class="info ellipsis_three" v-text="item.info">
                        <!-- <div class="more_text">......MORE</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            list:[
                // {img:"",title:"上线 - BIMOM构件库正式上线！",info:"2021年8月9日，在北京召开的人民代表大会上，MR.han获得包括国家自然科学奖、国家技术发明奖、国家科学技术进步奖,授予外籍科学家或外国组织的中华人民共和国国际科学技术合作奖,以及分量最重的国家最高科学技术奖。 1、国家最高科学技术奖 授予在当代科学技术前沿取得重大突破或者在科学技术发展中有卓越建树、在科学技术创新、科学技术成果转化和高技术产,1、国家最高科学技术奖 授予在当代科学技术前沿取得重大突破或者在科学技术发展中有卓越建树、在科学技术创新、科学技术成果转化和高技,科学技术奖 授予在当代科学技术前沿取得重大突破或者在科学技术发展中有卓越建树、在科学技术创新、科学技术成果转化和高技"},
                // {img:"",title:"上线 - BIMOM-BCF正式上线！",info:"2021年8月9日，在北京召开的人民代表大会上，MR.han获得包括国家自然科学奖、国家技术发明奖、国家科学技术进步奖,授予外籍科学家或外国组织的中华人民共和国国际科学技术合作奖,以及分量最重的国家最高科学技术奖。 1、国家最高科学技术奖 授予在当代科学技术前沿取得重大突破或者在科学技术发展中有卓越建树、在科学技术创新、科学技术成果转化和高技术产"},
                // {img:"",title:"上线 - BIMOM-钢筋正式上线！",info:"2021年8月9日，在北京召开的人民代表大会上，MR.han获得包括国家自然科学奖、国家技术发明奖、国家科学技术进步奖,授予外籍科学家或外国组织的中华人民共和国国际科学技术合作奖,以及分量最重的国家最高科学技术奖。 1、国家最高科学技术奖 授予在当代科学技术前沿取得重大突破或者在科学技术发展中有卓越建树、在科学技术创新、科学技术成果转化和高技术产"},
            ],
            image:"http://www.wkhzz.com:8085/BimomWebSource/"
        }
    },
    mounted() {
        this.getDynamics();

    },
    methods:{
        dynamicDetail(title){
            this.$router.push({
                path:"/productDynamicDetail",
                query:{title: title}
            })
        },
        getDynamics(){
            this.list=[];
            this.axios.get(this.VUE_APP_BASE_API+'/CompanyDynamics/getAll').then((res)=>{
                if(res.data.resultCode==0){
                    res.data.resultData.forEach((val)=>{
                        let data={
                            img:val.image,
                            title:val.title,
                            info:val.content,
                        }
                        this.list.push(data);
                    });

                }else{
                    alert("error");//fgfgf
                }
            });
        },
        backHome(){
            this.$store.commit("changeHash","#slide1")
        }
    },
    components:{
        topBanner,
        titleEnzh
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.row{
    width: 100%;
    margin-top: 40px;
    display: flex;
    .poster{
        width: 310px;
        cursor:pointer;
        border-radius: 5px;
    }
    .title{
        width: 100%;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        cursor: pointer;
        &:hover{
            color: #02A7F0;
        }
    }
    .info{
        width: 100%;
        color: rgb(121, 121, 121);
        font-family: '幼圆', sans-serif;
        font-size: 18px;
        line-height: 30px;
        cursor: pointer;
        &::after{
            content: "......MORE";
            color: #02A7F0;
            font-size: 18px;
            font-family: '幼圆', sans-serif;
            line-height: 30px;
            padding-left: 30px;
        }
        &:hover{
            color: #fff;
        }
    }
    
}
</style>