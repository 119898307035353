import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showUnderstandTwo:false,
    showUnderstandThree:false,
    showUnderstandFour:false,
    showUnderstandFive:false,
    showUnderstandRebar:false,
    hash:"#slide1",
    sideMenuState:false,
  },
  mutations: {
    showSideMenu(state,value){
      state.sideMenuState = value
    },
    changeUnderstandState(state,value){
      if (value.num === 2) {
        state.showUnderstandTwo = value.status
      }else if(value.num === 3){
        state.showUnderstandThree = value.status
      }else if(value.num === 4){
        state.showUnderstandFour = value.status
      }else if(value.num === 5){
        state.showUnderstandFive = value.status
      }else if(value.num===6){
        state.showUnderstandRebar=value.status
      }
    },
    changeHash(state,value){
      state.hash = value
    },
  },
  actions: {
  },
  modules: {
  }
})
