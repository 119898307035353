<template>
<div>
  <div class="swiper-container">
    <div class="swiper-wrapper">
        <!-- 第一屏 -->
        <div class="swiper-slide" data-hash="slide1">
            <one-page></one-page>
        </div>
        
        <!-- 第二屏 -->
        <div class="swiper-slide" data-hash="slide2">
          <two-page></two-page>
        </div>
        
        <!-- 第三屏 -->
        <div class="swiper-slide" data-hash="slide3">
          <three-page></three-page>
        </div>

        <!-- 第四屏 -->
        <div class="swiper-slide" data-hash="slide4">
          <four-page></four-page>
        </div>

        <!-- 第五屏 -->
        <div class="swiper-slide" data-hash="slide5">
          <five-page></five-page>
        </div>

        <!-- 第五屏 -->
        <div class="swiper-slide" data-hash="slide6">
            <rebar-page></rebar-page>
        </div>

        <!-- 第六屏 -->
        <div class="swiper-slide" data-hash="slide7">
          <six-page></six-page>
        </div>

        <!-- 第七屏 -->
        <div class="swiper-slide" data-hash="slide8">
          <seven-page></seven-page>
        </div>
    </div>
    
  </div>
  <div class="button_box">
    <div class="swiper_icon swiper-button-prev"></div>
    <div class="swiper_icon swiper-button-next"></div>
  </div>
  
</div>
   
</template>

<script>
import Swiper from "@/assets/utils/swiper/swiper-bundle.min.js"
import onePage from "./onePage.vue"
import twoPage from "./twoPage.vue"
import threePage from "./threePage.vue"
import fourPage from "./fourPage.vue"
import fivePage from "./fivePage.vue"
import sixPage from "./sixPage.vue"
import sevenPage from "./sevenPage.vue"
import rebarPage from "./RebarPage.vue"
export default {
  name: 'Home',
  data(){
    return {

    }
  },
  mounted(){
    var swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        hashNavigation: {
          watchState:true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });
  },
  components: {
      onePage,
      twoPage,
      threePage,
      fourPage,
      fivePage,
      rebarPage,
      sixPage,
      sevenPage

  }
}
</script>
<style scoped>
.button_box >>> .swiper-button-next:after, .swiper-button-prev:after{
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.swiper-container {
    width: 100%;
    height: 100vh;
}
.swiper-button-prev{
  background: url("../../assets/image/home/swiperIcon/pre_page_icon.svg") no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover{
    background: url("../../assets/image/home/swiperIcon/pre_page_icon_active.svg") no-repeat;
    background-size: contain;
  }
}
.swiper-button-next{
  background: url("../../assets/image/home/swiperIcon/next_page_icon.svg") no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover{
    background: url("../../assets/image/home/swiperIcon/next_page_icon_active.svg") no-repeat;
    background-size: contain;
  }
}
.swiper_icon{
  width: 34px;
  height: 34px;
}
.button_box{
  width: 120px;
  height: 80px;
  position: fixed;
  bottom: 6%;
  right: 3%;
  z-index: 999;
}
</style>
