<template>
  <div>
    <div id="two_page">
      <div class="bgm">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 中间内容区 -->
        <div class="content_box x_between_y_center">
          <div style="flex:4">
            <div>
              <span class="title_large" v-text="left_data.title_large"></span>
              <span class="title_blue" v-text="left_data.title_blue"></span>
            </div>
            <div class="en_words" v-text="left_data.en_words"></div>
            <div class="horizon_line"></div>
            <div class="discribe_text" v-text="left_data.discribe_text"></div>
            <div class="y_center">
              <div class="bimom_btn" v-text="left_data.bimom_btn_left" @click="understand"></div>
              <div class="bimom_btn" style="margin-left:30px" v-text="left_data.bimom_btn_right" @click="downloadFile('zip')"></div>
            </div>
          </div>
          <div style="flex:6;margin-left:-50px" class="xy_center">
            <img :src="transData.img" id="two_page_main_img" class="img_size" @click="understand">
          </div>
          <div style="flex:6" class="block_3">
            <div v-for="(item,index) in discrible_list" :key="index">
              <div class="row">
                <div class="point_circle"></div>
                <span class="row_text" v-text="item.title"></span>
              </div>
            </div>
            
          </div>
          <div style="flex:2.2" class="xy_center">
            <div class="progress_box">
              <div v-for="(item,index) in progressList" :key="index" class="item_box x_center">
                <div class="column_x_center left">
                  <img :src="item.active ? require('../../assets/image/home/twoPage/progress/circle_active.svg') : require('../../assets/image/home/twoPage/progress/circle.svg')" class="pregress_circle"/>
                  <div v-if="index !== progressList.length-1" class="virticle_line" :style="{background:item.active ? '#fff' : '#797979'}"></div>
                </div>
                <div class="y_center right">
                  <img :src="item.icon" class="progress_icon">
                  <div :style="{color:item.active ? '#fff' : '#797979'}" class="progress_name" v-text="item.name"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 鼠标图标 -->
        <img src="../../assets/image/home/mouse_icon.svg" class="mouse_icon">

        <!-- 底部第三方链接按钮组 -->
        <link-group></link-group>
      </div>
    </div>
    
    <!-- 了解组件 -->
    <understand :showUnderstand="$store.state.showUnderstandTwo" 
                :data="transData"
                @understandBackHome="understandBackHome">
    </understand>
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import linkGroup from "@/components/linkGroup.vue"
export default {
    name: 'twoPage',
    data(){
        return {
          transData:{
            img:require("../../assets/image/home/twoPage/sofa.png"),
            top:0,
            left:0,
            width:"",
            theme:"black",
            num:2,
            productid:"BIMOM"
          },
          left_data:{
            title_large:"BIMOM",
            title_blue:"构件库",
            en_words:"3D Parametric Part",
            discribe_text:"集成化管理企业参数化构件",
            bimom_btn_left:"了解BIMOM",
            bimom_btn_right:"下载BIMOM开源版",
          },
          discrible_list:[
            {title:"集中管理企业内部参数化构件，可自定义分类管理构件，动态更新机制便捷高效"},
            {title:"参数化构件账号授权机制，非授权（或未登录）账号无法编辑使用构件，保障企业数据安全"},
            {title:"集中管理企业mod模型，可自定义分类管理模型，以此建立更有效的企业标准户型库，让设计管理更加标准化"},
            {title:"支持多种筛选方式，模糊搜索，收藏等，多种模型预览方式：列表，小图，大图，方便用户选择"},
            {title:"一键快速加载参数化构件到项目图库，一键快速放置构件模型，一键加载mod模型"},
            {title:"同时推出开源共享版，集成大量免费GDL,支持共享参数化开发，打造业内优质GDL使用环境"},
          ],
          progressList:[
            {icon:require("../../assets/image/home/twoPage/progress/progress_icon3.svg"),name:"part",active:true},
            {icon:require("../../assets/image/home/twoPage/progress/progress_icon3.svg"),name:"BCF",active:false},
            {icon:require("../../assets/image/home/twoPage/progress/progress_icon3.svg"),name:"Architecture",active:false},
            {icon:require("../../assets/image/home/twoPage/progress/progress_icon3.svg"),name:"Formwork",active:false},
            {icon:require("../../assets/image/home/twoPage/progress/progress_icon3.svg"),name:"Rebar",active:false},
          ],
        }
    },
    methods:{
      understand(){
        var two_page = document.getElementById("two_page")
        var two_page_main_img = document.getElementById("two_page_main_img")
        two_page.classList.add("animate__animated","animate__fadeOut","animate__slower")
        this.transData.top = two_page_main_img.getBoundingClientRect().top
        this.transData.left = two_page_main_img.getBoundingClientRect().left
        this.transData.width = this.$tool.getStyle(two_page_main_img).width
        this.$store.commit("changeUnderstandState",{
          status:true,
          num:2
        })
      },
      understandBackHome(){
        var two_page = document.getElementById("two_page")
        two_page.classList.remove("animate__animated","animate__fadeOut","animate__slower")
        this.$store.commit("changeUnderstandState",{
          status:false,
          num:2
        })
      },
      backHome(){       
        this.$store.commit("changeHash","#slide1")
      },
      downloadFile(type) {
        let config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        let param = {
          product_id:this.transData.productid,
          product_type:type
        }
        this.axios.post(this.VUE_APP_BASE_API + '/ProductDownload/GetproductInfo', param, config).then((res) => {
          if (res.data.resultCode == 0) {
            let path=res.data.resultData;
            console.log(path);
            window.open(res.data.resultData);
          } else {
            alert(res.data.resultMsg);
          }
        });
      },
      download() {
        // window.open("http://www.wkhzz.com:8085/BimomWebSource/BIMOM%E5%BB%BA%E7%AD%91%E5%B7%A5%E5%85%B7%E5%8C%85V23%E3%80%81V24%E3%80%81V25.zip");
        //alert("即将上线！敬请期待！");
        let para = {
          product_id:this.transData.productid
        }
        this.axios({
          method: 'post',
          url: this.VUE_APP_BASE_API + '/ProductDownload/ProductDownloadZip',
          responseType: 'blob',
          data: para
        }).then(res => {
                  if (res.data.type == 'application/json') {
                    this.handlerResponseError(res.data);
                  }else{
                    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                    // let fileName = Date.parse(new Date()) + '.zip'
                    let fileName = decodeURIComponent(res.headers['filename']);// 'BIMOM构件库V1.0.zip'
                    console.log(fileName);
                    if (window.navigator.msSaveOrOpenBlob) {
                      // console.log(2)
                      navigator.msSaveBlob(blob, fileName)
                    } else {
                      // console.log(3)
                      var link = document.createElement('a')
                      link.href = window.URL.createObjectURL(blob)
                      link.download = fileName
                      link.click()
                      //释放内存
                      window.URL.revokeObjectURL(link.href)
                    }
                  }
                },
                err => {
                  alert(err);
                }
        );
      },
      handlerResponseError(data) {
        const _this = this;
        const fileReader = new FileReader();
        fileReader.onload = function() {
          try {
            const jsonData = JSON.parse(fileReader.result); // 说明是普通对象数据，后台转换失败
            console.log('后台返回的信息',jsonData.resultMsg);
            alert(jsonData.resultMsg);
            // dosomething……
          } catch (err) { // 解析成对象失败，说明是正常的文件流
            console.log('success...');
          }
        };
        fileReader.readAsText(data);
      },
    },
    components:{
        topBanner,
        linkGroup,
    }
}
</script>

<style lang="scss" scoped>
.img_size{
  cursor: pointer;
  width: 460px;
}
.bgm{
  width: 100%;
  height: 100vh;
  background: rgb(15, 15, 15);
  padding: 50px 70px !important;
}
.mouse_icon{
  position: absolute;
  bottom: 70px;
  right: 210px;
  width: 25px;
}
.progress_box{
  .item_box{
    .left{
      width: 50px;
      .pregress_circle{
        width: 20px;
        margin-bottom: -8px;
        margin-top: -4px;
      }
      .virticle_line{
        width: 1px;
        height: 100px;
      }
    }
    .right{
      width: 100px;
      margin-bottom: 60px;
      margin-top: -40px;
      .progress_icon{
        width:20px;
        margin-right:14px
      }
      .progress_name{
        font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
        color: #797979;
        font-size: 18px;
      }
    }
  }
  
  
}
.block_3{
  .row{
    display: flex;
    font-family: '幼圆', sans-serif;
    margin-bottom: 30px;
    .point_circle{
      width: 8px;
      height: 8px;
      background: #fff;
      border-radius: 50%;
      margin-right: 5px;
      margin-top: 12px;
    }
    .row_text{
      font-size:18px;
      line-height: 32px;
    }
  }
  
}
.content_box{
  color: #fff;
  margin-top: 150px;
  padding-left: 100px;
  .bimom_btn{
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #797979;
    border-radius: 20px;
    font-family: '幼圆', sans-serif;
    font-size: 14px;
    color: #797979;
    cursor: pointer;
    &:hover{
      color: #fff;
      border: 1px solid #fff;
    }
  }
  .title_large{
    font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
    font-weight: bold;
    font-size: 60px;
  }
  .title_blue{
    font-family: '幼圆', sans-serif;
    color: #02A7F0;
    font-size: 30px;
    margin-left: 10px;
  }
  .en_words{
    font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 48px;
    margin-top: 30px;
  }
  .horizon_line{
    width: 70px;
    height: 2px;
    background: #fff;
    margin: 40px 0 60px;
  }
  .discribe_text{
    font-family: '幼圆', sans-serif;
    font-size: 24px;
    margin-bottom: 60px;
  }
}
</style>