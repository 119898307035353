<template>
  <div v-if="showUnderstand" 
       :class="[data.theme === 'white' ? 'black_bgc' : 'white_bgc']">
        <!-- 顶部栏 -->
        <top-banner :theme="data.theme" @backHome="backHome"></top-banner>

        <!-- 左边菜单 -->
        <div class="menu animate__animated animate__slideInLeft animate__slow animate__delay-1s">
            <div v-for="(item,index) in list" :key="index" class="row" @click="changeMenu(index)">
                <div v-show="data.theme === 'white'">
                    <div :style="{color:current === index ? '#fff' : '#797979'}" class="en" v-text="item.en"></div>
                    <div :style="{color:current === index ? '#fff' : '#8A8A8A'}" class="zh" v-text="item.zh"></div>
                </div>
                <div v-show="data.theme === 'black'">
                    <div :style="{color:current === index ? '#000' : '#797979'}" class="en" v-text="item.en"></div>
                    <div :style="{color:current === index ? '#000' : '#8A8A8A'}" class="zh" v-text="item.zh"></div>
                </div>
            </div>
        </div>
        
        <!-- 右边视频 -->
        <div :class="[data.theme === 'white' ? 'white_rect' : 'black_rect']">
<!--            <img :src="current_video" ref="video" class="video" style="display:none">-->
            <video id="myVideo" ref="video" class="video" controls preload name="media" style="display:none" >
                <source :src="current_video" type="video/mp4" >  </video>
        </div>
        <img :src="data.img"
             ref="img"
             class="img"
             :style="{top:data.top + 'px',left:data.left + 'px',width:data.width,display:'block'}">
        
        <!-- 底部第三方链接按钮组 -->
        <link-group></link-group>
  </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import linkGroup from "@/components/linkGroup.vue"
export default {
  name:"understand",
  props:{
      showUnderstand:{
          type:Boolean,
          default:false
      },
      data:{
          type:Object,
          default:{}
      },
  },
  components:{ 
      topBanner,
      linkGroup
  },
  data() {
    return {
        list:[
            {
                en:"PRODUCT",
                zh:"产品视频",
                gif:require("../assets/image/understand/gif0.gif"),
                videos:"http://www.wkhzz.com:8085/BimomWebSource/"+this.data.productid+".mp4"
            },
            // {
            //     en:"DEVEIOPMENT",
            //     zh:"定制",
            //     gif:require("../assets/image/understand/gif1.gif"),
            //     videos:"http://www.wkhzz.com:8085/BimomWebSource/"+this.data.productid+"1.mp4"
            // },
            {
                en:"FUNCTION",
                zh:"功能演示",
                gif:require("../assets/image/understand/gif1.gif"),
                videos:"http://www.wkhzz.com:8085/BimomWebSource/"+this.data.productid+"1.mp4"
            },
            {
                en:"TUTORIALS",
                zh:"产品教程",
                gif:require("../assets/image/understand/gif1.gif"),
                videos:"http://www.wkhzz.com:8085/BimomWebSource/"+this.data.productid+"2.mp4"
            },
        ],
        current:"",
        current_video:"",
        current_gif:"",
        productid:this.data.productid
    }
  },

  methods:{
      changeMenu(index){

          console.log(this.productid);
          this.current = index;
          var video_dom = this.$refs.video;
          var img_dom = this.$refs.img;
          img_dom.style.display = "none";
          video_dom.style.display = "block";
          this.$refs.video.src=this.list[index].videos;
          this.current_video = this.list[index].videos;
          console.log(this.current_video);
      },
      backHome(){
        this.$emit("understandBackHome")
      }
  },
}
</script>

<style lang="scss" scoped>
// 背景色动画--start
.white_bgc{
     animation-name:whiteBgc;
     width: 100%;
    height: 100vh;
    padding: 50px 70px !important;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration:2s;
    animation-fill-mode : forwards
}
.black_bgc{
     animation-name:blackBgc;
     width: 100%;
    height: 100vh;
    padding: 50px 70px !important;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration:2s;
    animation-fill-mode : forwards
}
@keyframes whiteBgc {
    0%{
        background: transparent;
    }
    100%{
        background: rgb(255, 255, 255);
    }
}
@keyframes blackBgc {
    0%{
        background: transparent;
    }
    100%{
        background: rgb(0, 0, 0);
    }
}
// 背景色动画--end

// 矩形框动画--start
.white_rect{
    animation-name:whiteRect;
    position: absolute;
    left: 0%;
    top: 0%;
    animation-duration:2s;
    animation-delay:1s;
    animation-fill-mode : forwards
}
.black_rect{
    animation-name:blackRect;
    position: absolute;
    left: 0%;
    top: 0%;
    animation-duration:2s;
    animation-delay:1s;
    animation-fill-mode : forwards
}
@keyframes  whiteRect {
    0%{
        background: #000;
        width: 100%;
        height: 100vh;
    }
    100%{
        left: 36%;
        top: 20%;
        background: #fff;
        width: 60%;
        height: 70vh;
    }
}
@keyframes  blackRect {
    0%{
        background: #fff;
        width: 100%;
        height: 100vh;
    }
    100%{
        left: 36%;
        top: 20%;
        background: #000;
        width: 60%;
        height: 70vh;
    }
}
// 矩形框动画--end

// 图片动画--start
.img{
    position: absolute;
    animation-name:imgTranslate;
    animation-duration:2s;
    animation-delay:1s;
    animation-fill-mode : forwards
}
@keyframes imgTranslate {
    100%{
        left: 55%;
        top: 30%;
    }
}
// 图片动画--end
.menu{
    margin-top: 100px;
    margin-left: 40px;
    .row{
        margin-top:40px;
        cursor: pointer;
         .en{
            font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
            font-size: 54px;
        }
        .zh{
            font-family: '幼圆', sans-serif;;
            letter-spacing: 10px;
            font-size: 18px;
            margin-top: 10px;
        }
    }
}
.video{
    width: 100%;
    height: 70vh;
}
</style>