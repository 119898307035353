<template>
<div class="bgm">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>

        <div class="x_between">
            <!-- 左边 -->
            <div class="left" style="flex:1">
                <!-- 标题 -->
                <title-enzh enTitle="ABOUT US" zhTitle="关于我们" extra="...MORE" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                <!-- 放地图 -->
                <img src="../../assets/image/home/sevenPage/addressPic.jpg" class="map_size">
                <!-- 公司信息 -->
                <div class="company_info">
                    <div class="address">成都公司：四川省成都市高新区天府五街</div>
                    <div class="wrap_x_between">
                        <div v-for="(item,index) in infoList" :key="index" 
                             class="info_item y_center"
                             @mouseenter="iconEnter(index)"
                             @mouseleave="iconLeave(index)">
                            <img :src="current === index ? item.icon_sel : item.icon" class="item_icon">
                            <div v-text="item.name" class="item_name"></div>
                        </div>
                        <div class="empty"></div>
                    </div>
                </div>
                <div class="copyright">
                    <span>版权所有 成都悟空和展昭科技有限公司  丨  </span>
                    <span class="router_link" @click="routerLink">蜀ICP备2021015380号</span>
                </div>
            </div>
            <!-- 右边 -->
            <div class="right" style="flex:1">
                <!-- 标题 -->
                <title-enzh enTitle="CONTACT US" zhTitle="联系我们" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>

                <div class="x_between">
                    <div class="input_box" style="width:50%">
                        <div>
                            <span class="lable">姓名</span>
                            <span class="require_icon">*</span>
                        </div>
                        <input type="text" class="input_rect" v-model="user_name" style="width:70%">
                    </div>
                    <div class="input_box" style="width:50%">
                        <div>
                            <span class="lable">电话</span>
                            <span class="require_icon">*</span>
                        </div>
                        <input type="text" class="input_rect" v-model="user_phone" style="width:70%">
                    </div>
                </div>
                <div class="input_box" style="width:100%">
                    <div>
                        <span class="lable">邮箱</span>
                        <span class="require_icon"></span>
                    </div>
                    <input type="text" class="input_rect" v-model="user_email" style= "width:100%">
                </div>
                <div class="input_box" style="width:100%">
                    <div>
                        <span class="lable">企业</span>
                        <span class="require_icon">*</span>
                    </div>
                    <input type="text" class="input_rect" v-model="user_company" style="width:100%">
                </div>
                <div class="input_box" style="width:100%">
                    <div>
                        <span class="lable">需求及建议</span>
                        <span class="require_icon">*</span>
                        <span class="require_icon"></span>
                    </div>
                    <textarea cols="30" rows="7" v-model="feed_description" style="width:100%;"></textarea>
                </div>

                <!-- 提交按钮 -->
                <div class="submit" @click="sendmsg">提 交</div>
            </div>
        </div>      
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    name: 'twoPage',
    data(){
        return {
           current:"",
           infoList:[
               {
                    icon:require("../../assets/image/home/sevenPage/phone.svg"),
                    icon_sel:require("../../assets/image/home/sevenPage/phone_selec.svg"),
                    name:"15959358326"
               },
               {
                   icon:require("../../assets/image/home/sevenPage/wx.svg"),
                   icon_sel:require("../../assets/image/home/sevenPage/wx_selec.svg"),
                   name:"fbr0321"
                },
               {
                   icon:require("../../assets/image/home/sevenPage/qq.svg"),
                   icon_sel:require("../../assets/image/home/sevenPage/qq_selec.svg"),
                   name:"546570287"
                },
               // {
               //     icon:require("../../assets/image/home/sevenPage/help.svg"),
               //     icon_sel:require("../../assets/image/home/sevenPage/help_selec.svg"),
               //     name:"点击咨询在线客服"
               //  },
               {
                   icon:require("../../assets/image/home/sevenPage/email.svg"),
                   icon_sel:require("../../assets/image/home/sevenPage/email_selec.svg"),
                   name:"546570287@qq.com"
                },
           ],
            user_name:"",
            user_phone:"",
            user_email:"",
            user_company:"",
            feed_description:"",
        }
    },
    methods:{
        iconEnter(index){
            this.current = index
        },
        iconLeave(){
            this.current = ""
        },
        routerLink(){
            window.open("https://beian.miit.gov.cn/#/Integrated/index",'_blank')
        },
        backHome(){
            this.$store.commit("changeHash","#slide1")
        },
        sendmsg(){
            let param = {
                feed_type: "联系我们",
                user_name: this.user_name,
                user_phone: this.user_phone,
                user_account: this.user_account,
                user_email:this.user_email,
                user_company:this.user_company,
                feed_description:this.feed_description,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + '/Feedback/insertFeedback', JSON.stringify(param), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                    alert("提交成功");
                } else {
                    alert("提交失败:  "+ res.data.resultMsg);
                }
            });
        }
    },
    components:{
        topBanner,
        titleEnzh
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.empty{
    width: 250px;
    height: 0;
}
.left{
    padding-right: 60px;
    .map_size{
        width: 100%;
        height: 470px;
        margin: 30px 0;
        border-radius: 10px;
    }
    .company_info{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 18px;
        color: #797979;
        margin-bottom: 20px;
        .address{
            margin-bottom:30px
        }
        .info_item{
            font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
            margin-bottom:26px;
            width: 250px;
            cursor: pointer;
            &:hover .item_name{
                color: #fff;
            }
            .item_icon{
                width:30px;
                margin-right:20px
            }
            .item_name{
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
    .copyright{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 16px;
        color: #797979;
        .router_link{
            cursor: pointer;
            &:hover{
                color: rgb(149, 242, 4);       
            }
        }
    }
}

.right{
    padding-left: 60px;
    .submit{
        width: 140px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
        font-size: 18px;
        color: #797979;
        border: 1px solid #797979;
        letter-spacing: 4px;
        border-radius: 20px;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
        margin-top: 30px;
        &:hover{
            color: #fff;
            border: 1px solid #fff;
        }
    }
}
// 输入框
.input_box{
    margin-top: 30px;
    .input_rect{
        height: 40px;
        padding: 0 20px;
        border-radius: 6px;
        margin-top: 10px;
        background: rgba($color: #fff, $alpha: .5);
        color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .lable{
        font-family: '幼圆', sans-serif;
        color: #fff;
        margin-right: 6px;
        font-size: 22px;
    }
    .require_icon{
        font-size: 26px;
        color: #02A7F0;
        vertical-align: middle;
    }
}
textarea{
    background: rgba($color: #fff, $alpha: .5);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline:none;
    padding: 20px;
    margin-top: 10px;
}

</style>