
let tools = {
    getStyle(el){
        if(window.getComputedStyle) {
    　 　 　 return window.getComputedStyle(el, null);
    　 　 }else{
    　 　 　 return el.currentStyle;
    　 　 }
    }
}
export default tools