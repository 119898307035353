<template>
  <div class="wrapper x_between_y_center">
      <div v-for="(item,index) in list" :key="index">
        <div @mouseenter="mouseEnter(index)">
            <img :src="current === index ? item.iconActive : item.icon" class="icon">
        </div>
        <div class="info_box">
            <div class="info animate__animated animate__fast" 
                :class="[current === item.id ? 'animate__fadeInLeft' : 'animate__fadeOutLeft']" 
                v-text="info">
            </div>
        </div>
      </div>
      
      
  </div>
</template>

<script>

export default {
  data() {
    return {
        current:"",
        info:"",
        list:[
            {
                icon:require("../assets/image/linkGroup/icon0.svg"),
                iconActive:require("../assets/image/linkGroup/icon0_active.svg"),
                info:"15959358326",
                id:0
            },
            {
                icon:require("../assets/image/linkGroup/icon1.svg"),
                iconActive:require("../assets/image/linkGroup/icon1_active.svg"),
                info:"fbr0321",
                id:1
            },
            {
                icon:require("../assets/image/linkGroup/icon2.svg"),
                iconActive:require("../assets/image/linkGroup/icon2_active.svg"),
                info:"546570287",
                id:2
            },
            { 
                icon:require("../assets/image/linkGroup/icon3.svg"),
                iconActive:require("../assets/image/linkGroup/icon3_active.svg"),
                info:"咨询线上客服",
                id:3
            },
        ]
    }
  },
  methods:{
    mouseEnter(index){
        this.current = index
        this.info = this.list[index].info
    },
  },

}
</script>

<style lang="scss" scoped>
.wrapper{
    position: absolute;
    bottom: 4%;
    left: 3%;
    width: 9%;
}
.icon{
    position: relative;
    z-index: 2;
    width:28px;
    cursor: pointer;
}
.info_box{
    position: absolute;
    left: 108%;
    top: 16%;
    width: 160px;
    overflow: hidden;
}
.info{
    font-family: '幼圆', sans-serif;
    color: #02A7F0;
    font-size: 16px;
    position: relative;
    z-index: 1;
    
}
</style>