<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {
    
  },
}
</script>

<style lang="scss">


</style>
